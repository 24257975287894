<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-card-title>Podaj nowe hasło</v-card-title>
        <v-card-text>
            <v-form ref="loginForm" v-model="valid">
              <v-text-field
                v-model="resetCode"
                type="number"
                label="Kod"
                outlined
                dense
                single-line
                validate-on-blur
                :rules="codeRules"
                ></v-text-field>
                <v-text-field
                v-model="password"
                type="password"
                :label="$t('login.password')"
                outlined
                dense
                single-line
                validate-on-blur
                :rules="passwordRules"
                ></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn btn color="primary" block depressed :disabled="submitLoading" :loading="submitLoading" @click="submitHandle">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Service from '@/services'
export default {
  name: 'ResetCode',
  props: ['email'],
  data () {
    return {
      dialog: false,
      password: '',
      submitLoading: false,
      valid: true,
      resetCode: null,
      passwordRules: [
        v => !!v.trim() || this.$t('validation.passwordRequired')
      ],
      codeRules: [
        (value) => !!value || 'Pole wymagane',
        (value) => (value || '').length === 6 || 'Kod ma 6 cyfr'
      ]
    }
  },
  methods: {
    show () {
      this.dialog = true
    },
    async submitHandle () {
      if (!this.$refs.loginForm.validate()) {
        return
      }

      this.submitLoading = true

      try {
        await Service.post('Authentication/ResetPassword', {
          email: this.email,
          resetCode: this.resetCode,
          password: this.password
        })

        this.$router.push({ name: 'Login' })

        this.$refs.loginForm.resetValidation()
      } catch (e) {
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
